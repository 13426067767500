import React from "react";
// import FlipCountdown from "@rumess/react-flip-countdown";

const ComingSoonPage = () => {
  return (
    <div className="flex justify-center items-center h-screen max-h-screen bg-black overflow-hidden">
      <div className="relative flex flex-col md:flex-row justify-between items-center max-w-7xl w-full h-full">
        <div className="flex flex-col justify-center items-center text-white mb-4 md:mb-0 md:order-1 order-2 md:w-1/2 h-full">
          <h1
            className="text-3xl md:text-4xl font-bold mb-14"
            style={{ color: "#D4C198", fontSize: "4rem" }}
          >
            COMING SOON!
          </h1>
          {/* <FlipCountdown
            monthTitle=" "
            dayTitle=" "
            hourTitle=" "
            // minuteTitle=" "
            // secondTitle=" "
            hideYear
            hideSecond
            hideMinute
            // hideMonth
            theme="dark"
            endAt={"2024-09-30 00:00:00"}
          /> */}
          {/* <div className="flex w-full ml-40 mt-4 text-2xl pl-16">
            <div className="flex flex-col items-center mr-20">
              <span className="block">Months</span>
            </div>
            <div className="flex flex-col items-center mr-20">
              <span className="block">Days</span>
            </div>
            <div className="flex flex-col items-center mr-20">
              <span className="block">Hours</span>
            </div>
            <div className="flex flex-col items-center mr-16">
              <span className="block">Minutes</span>
            </div>
            <div className="flex flex-col items-center">
              <span className="block">Seconds</span>
            </div>
          </div> */}
        </div>
        <div className="w-full md:w-1/2 h-full md:order-2 order-2">
          <div className="flex justify-center md:justify-end h-full mask-image">
            <div className="h-full mask-image2">
              <img
                src="/poster.jpg"
                alt="Coming Soon"
                className="w-full md:w-auto h-full object-cover max-w-full max-h-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoonPage;
